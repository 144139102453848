
import { defineComponent } from "vue";
// import WaypointsTable from "./WaypointsTable.vue";
import WaypointsMap from "./WaypointsMap.vue";

export default defineComponent({
  components: {
    // WaypointsTable,
    WaypointsMap,
  },
  setup() {
    return {};
  },
});
