
import { defineComponent } from "vue";
import Waypoints from "@/components/Waypoints.vue";
export default defineComponent({
  components: {
    Waypoints,
  },
  setup() {
    return {};
  },
});
