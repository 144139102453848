<template>
  <div class="registration-form">
    <Dialog v-model:visible="showMessage" :breakpoints="{ '960px': '80vw' }" :style="{ width: '30vw' }" position="top">
      <div class="flex align-items-center flex-column pt-6 px-3">
        <i class="pi pi-check-circle" :style="{ fontSize: '5rem', color: 'var(--green-500)' }"></i>
        <h5>Registration Successful!</h5>
        <p :style="{ lineHeight: 1.5, textIndent: '1rem' }">
          Thank you for registering, please check your email for activation
          instructions.
        </p>
      </div>
      <template #footer>
        <div class="flex justify-content-center">
          <Button label="OK" @click="toggleDialog" class="p-button-text" />
        </div>
      </template>
    </Dialog>
    <div class="flex justify-content-center ">
      <div class="card">
        <h5 class="text-center ">Register</h5>
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
          <div class="field">
            <div class="p-float-label">
              <InputText id="firstName" v-model="v$.firstName.$model"
                :class="{ 'p-invalid': v$.firstName.$invalid && submitted }" />
              <label for="firstName" :class="{ 'p-error': v$.firstName.$invalid && submitted }">First Name*</label>
            </div>
            <small v-if="
              (v$.firstName.$invalid && submitted) || v$.firstName.$pending
            " class="p-error">{{
              v$.firstName.required.$message.replace("Value", "First Name")
              }}</small>
          </div>
          <div class="field">
            <div class="p-float-label">
              <InputText id="lastName" v-model="v$.lastName.$model"
                :class="{ 'p-invalid': v$.lastName.$invalid && submitted }" />
              <label for="lastName" :class="{ 'p-error': v$.lastName.$invalid && submitted }">Last Name*</label>
            </div>
            <small v-if="(v$.lastName.$invalid && submitted) || v$.lastName.$pending" class="p-error">{{
              v$.lastName.required.$message.replace("Value", "Last Name")
            }}</small>
          </div>
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-envelope" />
              <InputText id="email" v-model="v$.email.$model" :class="{ 'p-invalid': v$.email.$invalid && submitted }"
                aria-describedby="email-error" />
              <label for="email" :class="{ 'p-error': v$.email.$invalid && submitted }">Email*</label>
            </div>
            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending" class="p-error">{{
              v$.email.required.$message.replace("Value", "Email")
            }}</small>
          </div>
          <div class="field">
            <div class="p-float-label">
              <Password id="password" v-model="v$.password.$model"
                :class="{ 'p-invalid': v$.password.$invalid && submitted }" toggleMask>
                <template #header>
                  <h6>Pick a password</h6>
                </template>
                <template #footer="sp">
                  {{ sp.level }}
                  <Divider />
                  <p class="mt-2">Suggestions</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <label for="password" :class="{ 'p-error': v$.password.$invalid && submitted }">Password*</label>
            </div>
            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending" class="p-error">{{
              v$.password.required.$message.replace("Value", "Password")
            }}</small>
          </div>
          <Button type="submit" label="Submit" class="mt-2" disabled />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { getAuth, createUserWithEmailAndPassword, connectAuthEmulator } from "firebase/auth";
export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();

    const formData = reactive({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    });

    const rules = {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      password: { required },
    };

    const submitted = ref(false);
    const showMessage = ref(false);



    const v$ = useVuelidate(rules, formData);

    const handleSubmit = (isFormValid) => {

      submitted.value = true;
      if (!isFormValid) {
        return;
      }
      const auth = getAuth();
      // connectAuthEmulator(auth, "http://127.0.0.1:9099");
      createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      )
        .then((data) => {
          console.log("Registration Success");
          router.push("/profile");
        })
        .catch((err) => {
          console.log("Registration Error");
          alert(err.message);
        });
      toggleDialog();
    };

    const toggleDialog = () => {
      showMessage.value = !showMessage.value;

      if (!showMessage.value) {
        resetForm();
        router.push({ name: "Profile" });
      }
    };

    const resetForm = () => {
      formData.firstName = "";
      formData.lastName = "";
      formData.email = "";
      formData.password = "";
      submitted.value = false;
    };

    return {
      formData,
      v$,
      handleSubmit,
      toggleDialog,
      submitted,
      showMessage,
    };
  },
});
</script>

<style land="less" scoped>
.card {
  min-width: 450px;
}

form {
  margin-top: 3rem;
}

.field {
  margin-bottom: 1.5rem;
}

.text {
  /* text-decoration-line: line-through; */
}
</style>
