
import { defineComponent } from "vue";
import { Map, LngLatLike } from "mapbox-gl";
import Waypoints from "@/assets/geo/waypoints";

export default defineComponent({
  components: {},
  setup() {
    const mapboxAccessToken = process.env.VUE_APP_MAPBOX_API_KEY;
    const launchCenter: LngLatLike = [-108.438723, 39.437221];
    const mapCenter: LngLatLike = [-108.43, 39.5];

    const handleLoaded = (mapboxMap: Map) => {
      mapboxMap.flyTo({
        center: mapCenter,
        maxDuration: 3000,
        speed: 1,
        zoom: 8,
        pitch: 0,
        curve: 1,
        easing(t) {
          return t;
        },
      });
    };

    //TODO: draw retrieve route on map
    return {
      mapboxAccessToken,
      launchCenter,
      mapCenter,
      Waypoints,
      handleLoaded,
    };
  },
});
