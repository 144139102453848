const retrieveRoutes = 
{
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "stroke": "#ff00f0",
        "stroke-width": 5,
        "stroke-opacity": 1,
        "name": "Horse"
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -108.44999313354492,
            39.350361161445846
          ],
          [
            -108.4435987472534,
            39.35119079937722
          ],
          [
            -108.43711853027344,
            39.35135672578145
          ],
          [
            -108.43364238739014,
            39.3500293035152
          ],
          [
            -108.43115329742432,
            39.3507925743995
          ],
          [
            -108.41102600097656,
            39.35314870584022
          ],
          [
            -108.39664936065674,
            39.36117886678893
          ],
          [
            -108.38768005371092,
            39.36529313705039
          ],
          [
            -108.3856201171875,
            39.365259958420864
          ],
          [
            -108.38227272033691,
            39.36622213227738
          ],
          [
            -108.38047027587889,
            39.36784784418894
          ],
          [
            -108.37626457214355,
            39.37040245787161
          ],
          [
            -108.37540626525879,
            39.37060151475316
          ],
          [
            -108.3742046356201,
            39.37182901964995
          ],
          [
            -108.3665657043457,
            39.376871516048496
          ],
          [
            -108.36381912231445,
            39.3775018024908
          ],
          [
            -108.36416244506836,
            39.3790609076619
          ],
          [
            -108.36347579956055,
            39.379790689607155
          ],
          [
            -108.35978507995605,
            39.38115071741885
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "stroke": "#0000ff",
        "stroke-width": 5,
        "stroke-opacity": 1,
        "name": "Kimball Creek"
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -108.48750114440918,
            39.464493004645995
          ],
          [
            -108.48149299621582,
            39.46369784542551
          ],
          [
            -108.48029136657715,
            39.46429421569262
          ],
          [
            -108.47797393798828,
            39.46402916287149
          ],
          [
            -108.47720146179199,
            39.46346592227486
          ],
          [
            -108.47312450408936,
            39.4637641090409
          ],
          [
            -108.46956253051756,
            39.46515563038962
          ],
          [
            -108.46677303314209,
            39.464989974545205
          ],
          [
            -108.46372604370117,
            39.46575198816448
          ],
          [
            -108.46269607543944,
            39.466447732442724
          ],
          [
            -108.46145153045654,
            39.46638147138258
          ],
          [
            -108.45917701721191,
            39.46701094890658
          ],
          [
            -108.45651626586914,
            39.466845297478635
          ],
          [
            -108.45492839813232,
            39.46727599037104
          ],
          [
            -108.45398426055908,
            39.46711033957404
          ],
          [
            -108.45252513885498,
            39.46750790082436
          ],
          [
            -108.45067977905273,
            39.46717659994016
          ],
          [
            -108.44643115997314,
            39.467872329975165
          ],
          [
            -108.43231201171875,
            39.46595077295377
          ],
          [
            -108.4312391281128,
            39.46538754790934
          ],
          [
            -108.43038082122803,
            39.46542067892049
          ],
          [
            -108.42051029205322,
            39.46098098287345
          ],
          [
            -108.41209888458252,
            39.458065207521365
          ],
          [
            -108.40506076812744,
            39.45425463517536
          ],
          [
            -108.40128421783447,
            39.45269721078135
          ],
          [
            -108.39815139770508,
            39.449482841776344
          ],
          [
            -108.39433193206787,
            39.44769333493737
          ],
          [
            -108.39042663574219,
            39.44683170413135
          ],
          [
            -108.38566303253174,
            39.446699144599535
          ],
          [
            -108.37892532348633,
            39.44716310185687
          ],
          [
            -108.37218761444092,
            39.44712996215531
          ],
          [
            -108.37047100067139,
            39.44703054295593
          ],
          [
            -108.3616304397583,
            39.449350287291324
          ],
          [
            -108.35532188415527,
            39.45312799122698
          ],
          [
            -108.35343360900879,
            39.45362504227522
          ],
          [
            -108.33257675170898,
            39.45345935898677
          ],
          [
            -108.32489490509032,
            39.454652269862954
          ],
          [
            -108.31862926483153,
            39.45345935898677
          ],
          [
            -108.3037805557251,
            39.453359948824435
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "stroke": "#00ff00",
        "stroke-width": 5,
        "stroke-opacity": 1,
        "name": "North Dry Fork"
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -108.50797176361084,
            39.42164086263145
          ],
          [
            -108.50760698318481,
            39.42076233343014
          ],
          [
            -108.50597620010376,
            39.42034792901999
          ],
          [
            -108.50524663925171,
            39.41953568922911
          ],
          [
            -108.50252151489258,
            39.41928704250297
          ],
          [
            -108.50185632705688,
            39.418458213676786
          ],
          [
            -108.4986162185669,
            39.41801064201294
          ],
          [
            -108.49792957305908,
            39.41834217685469
          ],
          [
            -108.49634170532227,
            39.41804379556806
          ],
          [
            -108.493595123291,
            39.41683368059009
          ],
          [
            -108.49196434020995,
            39.417529913691595
          ],
          [
            -108.48488330841064,
            39.41573958595289
          ],
          [
            -108.48331689834595,
            39.41582247251141
          ],
          [
            -108.47773790359497,
            39.414247611048026
          ],
          [
            -108.47527027130127,
            39.414231033369745
          ],
          [
            -108.4720516204834,
            39.415093067414354
          ],
          [
            -108.4710431098938,
            39.415540657810396
          ],
          [
            -108.46797466278076,
            39.415855627107234
          ],
          [
            -108.4644341468811,
            39.41767910559437
          ],
          [
            -108.46222400665283,
            39.418673710119656
          ],
          [
            -108.46014261245728,
            39.41917100706057
          ],
          [
            -108.45733165740967,
            39.419137854041566
          ],
          [
            -108.45565795898438,
            39.42059657196175
          ],
          [
            -108.45295429229736,
            39.4208120617938
          ],
          [
            -108.45196723937988,
            39.42112700727306
          ],
          [
            -108.45022916793823,
            39.4210772791341
          ],
          [
            -108.44769716262817,
            39.42180662161637
          ],
          [
            -108.44728946685791,
            39.422270744676915
          ],
          [
            -108.44542264938354,
            39.42293377225927
          ],
          [
            -108.44424247741698,
            39.42275144030286
          ],
          [
            -108.44213962554932,
            39.42314925486548
          ],
          [
            -108.42892169952393,
            39.42288404540938
          ],
          [
            -108.42572450637817,
            39.42241992643287
          ],
          [
            -108.42535972595215,
            39.42243650216381
          ],
          [
            -108.41851472854614,
            39.42026504784228
          ],
          [
            -108.41570377349854,
            39.420132437753004
          ],
          [
            -108.41458797454834,
            39.419436230645076
          ],
          [
            -108.4120774269104,
            39.417795143520216
          ],
          [
            -108.40971708297728,
            39.41643582998149
          ],
          [
            -108.4079360961914,
            39.41459574138174
          ],
          [
            -108.4081506729126,
            39.41368396777447
          ],
          [
            -108.40795755386353,
            39.412788760268185
          ],
          [
            -108.38291645050049,
            39.40012200014591
          ],
          [
            -108.37579250335693,
            39.39547915593726
          ],
          [
            -108.37167263031006,
            39.39309128716404
          ],
          [
            -108.3665657043457,
            39.38705492114784
          ],
          [
            -108.36283206939697,
            39.38342289988585
          ],
          [
            -108.3597207069397,
            39.381499012962124
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "stroke": "#00ffff",
        "stroke-width": 5,
        "stroke-opacity": 1,
        "name": "204 Road"
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -108.32828521728516,
            39.48470025134107
          ],
          [
            -108.3262252807617,
            39.48450152012079
          ],
          [
            -108.32506656646729,
            39.482580422379556
          ],
          [
            -108.32592487335205,
            39.479963669400526
          ],
          [
            -108.3235216140747,
            39.47625367366696
          ],
          [
            -108.31090450286865,
            39.46363158174706
          ],
          [
            -108.30897331237793,
            39.462836412684275
          ],
          [
            -108.306827545166,
            39.4588272969601
          ],
          [
            -108.30661296844482,
            39.4572368399724
          ],
          [
            -108.30463886260986,
            39.45544753242463
          ],
          [
            -108.3035659790039,
            39.45339308556099
          ],
          [
            -108.30028295516968,
            39.449863934515136
          ],
          [
            -108.2971715927124,
            39.44830641190367
          ],
          [
            -108.28457593917847,
            39.44023656143002
          ],
          [
            -108.27502727508545,
            39.428768143005684
          ],
          [
            -108.27476978302002,
            39.419585418467875
          ],
          [
            -108.27305316925049,
            39.41219194887819
          ],
          [
            -108.27292442321776,
            39.409937281968105
          ],
          [
            -108.27090740203857,
            39.40847834099959
          ],
          [
            -108.2697057723999,
            39.40652198588407
          ],
          [
            -108.26773166656494,
            39.404897174662594
          ],
          [
            -108.26468467712402,
            39.401116855130475
          ],
          [
            -108.26399803161621,
            39.39693836884292
          ],
          [
            -108.26258182525635,
            39.38936007859263
          ],
          [
            -108.26022148132324,
            39.385495994536385
          ],
          [
            -108.25702428817749,
            39.383489239868396
          ],
          [
            -108.25614452362059,
            39.382427792580685
          ],
          [
            -108.25481414794922,
            39.3737365857852
          ],
          [
            -108.25245380401611,
            39.36771513443107
          ],
          [
            -108.25146675109863,
            39.36585717134055
          ],
          [
            -108.24816226959229,
            39.362174276979765
          ],
          [
            -108.23876380920409,
            39.3572634490742
          ],
          [
            -108.23258399963379,
            39.35178813258844
          ],
          [
            -108.2306957244873,
            39.34949832754797
          ],
          [
            -108.22794914245605,
            39.34836999022121
          ],
          [
            -108.22704792022705,
            39.34734119619177
          ],
          [
            -108.2266616821289,
            39.345183998248544
          ],
          [
            -108.22181224822998,
            39.34150001397156
          ],
          [
            -108.21687698364258,
            39.3402055950204
          ],
          [
            -108.21554660797119,
            39.33881157857386
          ],
          [
            -108.21335792541504,
            39.33801498526587
          ],
          [
            -108.21215629577637,
            39.33768306870847
          ],
          [
            -108.21116924285889,
            39.33695284673498
          ],
          [
            -108.20898056030273,
            39.33204024612027
          ],
          [
            -108.20760726928711,
            39.32891990162641
          ],
          [
            -108.20820808410645,
            39.326297376761524
          ],
          [
            -108.20816516876219,
            39.325733023109294
          ],
          [
            -108.20533275604248,
            39.323575158326776
          ],
          [
            -108.20507526397705,
            39.32281159022619
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "stroke": "#ffa200",
        "stroke-width": 5,
        "stroke-opacity": 1,
        "name": "South Dry Fork"
      },
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            -108.35858345031738,
            39.381349743656386
          ],
          [
            -108.35493564605713,
            39.381714623617945
          ],
          [
            -108.35201740264893,
            39.38091851942446
          ],
          [
            -108.35090160369872,
            39.380354606793695
          ],
          [
            -108.34729671478271,
            39.38115071741885
          ],
          [
            -108.34373474121094,
            39.380188749270566
          ],
          [
            -108.32991600036621,
            39.37760132088255
          ],
          [
            -108.32493782043457,
            39.3784306352965
          ],
          [
            -108.32189083099365,
            39.378165255755974
          ],
          [
            -108.3138656616211,
            39.372326650622746
          ],
          [
            -108.30875873565672,
            39.37090009901147
          ],
          [
            -108.30609798431396,
            39.3706346908449
          ],
          [
            -108.30390930175781,
            39.36957304809048
          ],
          [
            -108.30107688903809,
            39.36970575431752
          ],
          [
            -108.2953691482544,
            39.36990481318516
          ],
          [
            -108.29206466674805,
            39.36930763487986
          ],
          [
            -108.28794479370117,
            39.36983846029233
          ],
          [
            -108.28043460845947,
            39.369539871494325
          ],
          [
            -108.27910423278809,
            39.368909513172376
          ],
          [
            -108.27322483062744,
            39.369141751110924
          ],
          [
            -108.26807498931885,
            39.36970575431752
          ],
          [
            -108.26519966125488,
            39.36884315933378
          ],
          [
            -108.26138019561768,
            39.36784784418894
          ],
          [
            -108.26069355010986,
            39.36715111514669
          ],
          [
            -108.25374126434326,
            39.36535949426213
          ],
          [
            -108.25172424316406,
            39.36585717134055
          ]
        ]
      }
    }
  ]
}
  export default retrieveRoutes;