const Waypoints = {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": { "name": "TP0", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.35445, 39.419, 2407.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP1", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.323866667, 39.41525, 2382.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP2", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.439033333, 39.4468, 2375.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP3", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.545, 39.440466667, 2543.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP4", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.37335, 39.394133333, 1773.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP5", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.492883333, 39.364033333, 2007.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP6", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.508283333, 39.42175, 2062.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP7", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.513116667, 39.339266667, 2374.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP8", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.213016667, 39.333116667, 1510] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP9", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.189583333, 39.400516667, 2421.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP10", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.180283333, 39.435433333, 2670] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP11", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.250033333, 39.33975, 1748] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP12", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.227483333, 39.450333333, 2392.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP13", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.369183333, 39.467183333, 2468] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP14", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.4505, 39.40975, 2015] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP15", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.472133333, 39.38475, 2578] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP16", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.612333333, 39.401733333, 2527.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP17", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.488416667, 39.350266667, 2297.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP18", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.508566667, 39.310483333, 2441.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP19", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.345216667, 39.3474, 2238.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP20", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.263683333, 39.4544, 2372] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP21", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.62655, 39.506933333, 2663] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP22", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.84015, 39.542933333, 2507.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP23", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.094266667, 39.270833333, 2450.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP24", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.104283333, 39.309516667, 2257] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP25", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.132966667, 39.2769, 2371] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP26", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.438733333, 39.436583333, 2478.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP27", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.4337, 39.439083333, 2478.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP28", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.42515, 39.432833333, 2333.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP29", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.3802, 39.416733333, 2432.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP30", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.721983333, 39.520666667, 2279] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP31", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.67995, 39.4727, 2560.9] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP32", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.15915, 39.409066667, 2424.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP33", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.621433333, 39.463466667, 2588.1] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP34", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.256183333, 39.38345, 4876] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP35", "desc": "WPT", "pointValue": "5", "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.8126, 39.598016667, 2581] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP36", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.45067977905273, 39.38168145279117] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP37", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.468017578125, 39.43274584300144] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP38", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.4050178527832, 39.38566183944818] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP39", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.40124130249023, 39.425320619330286] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP40", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.39849472045898, 39.40569300529698] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP41", "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.4237289428711, 39.37982386133248] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP98", "desc": "LZ", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.42591762542725, 39.41989208132311] }
    },
    {
      "type": "Feature",
      "properties": { "name": "TP99", "desc": "HQ", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white" },
      "geometry": { "type": "Point", "coordinates": [-108.36495, 39.37805, 1730] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP42" },
      "geometry": { "type": "Point", "coordinates": [-108.79576206207275, 39.59892623460736] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP43" },
      "geometry": { "type": "Point", "coordinates": [-108.74679565429686, 39.59967024606959] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP44" },
      "geometry": { "type": "Point", "coordinates": [-108.72954368591309, 39.5692419266896] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP45" },
      "geometry": { "type": "Point", "coordinates": [-108.68765830993652, 39.524964723682494] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP46" },
      "geometry": { "type": "Point", "coordinates": [-108.7320327758789, 39.580157969285175] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP47" },
      "geometry": { "type": "Point", "coordinates": [-108.82807731628418, 39.57380702634555] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP48" },
      "geometry": { "type": "Point", "coordinates": [-108.82485866546631, 39.53759223156382] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP49" },
      "geometry": { "type": "Point", "coordinates": [-108.79992485046387, 39.51013315863607] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP50" },
      "geometry": { "type": "Point", "coordinates": [-108.81039619445801, 39.46966131812406] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP51" },
      "geometry": { "type": "Point", "coordinates": [-108.78893852233887, 39.45481795031247] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP52" },
      "geometry": { "type": "Point", "coordinates": [-108.77172946929932, 39.39355560138178] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP53" },
      "geometry": { "type": "Point", "coordinates": [-108.71426582336424, 39.46098098287345] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP54" },
      "geometry": { "type": "Point", "coordinates": [-108.78293037414551, 39.56136807756286] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP55" },
      "geometry": { "type": "Point", "coordinates": [-108.76361846923828, 39.539131207210346] }
    },
    {
      "type": "Feature",
      "properties": { "desc": "WPT", "pointValue": 5, "radiusKm": 0.4, "fillColor": "red", "outlineColor": "white", "name": "TP56" },
      "geometry": { "type": "Point", "coordinates": [-108.74645233154295, 39.49443738543468] }
    }
  ]
}



  

export default Waypoints;