
import { defineComponent, ref } from "vue";
import retrieveRoutes from "../assets/geo/retrieveRoutes";

export default defineComponent({
  components: {},
  setup() {
    const mapboxAccessToken = process.env.VUE_APP_MAPBOX_API_KEY;
    const launchCenter = [-108.4387, 39.4372];

    //TODO: draw retrieve route on map
    return {
      mapboxAccessToken,
      retrieveRoutes,
      launchCenter,
    };
  },
});
