
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  setup() {
    //TODO: draw retrieve route on map
    return {
      active: 0,
    };
  },
});
