import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Plugin } from "vue-responsive-video-background-player";
import VueMapboxTs from "vue-mapbox-ts";
import PrimeVue from "primevue/config";
import "primeflex/primeflex.css";

//Site wide UI components
import Image from "primevue/image";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tooltip from "primevue/tooltip";
import ScrollPanel from "primevue/scrollpanel";
import Panel from "primevue/panel";
import Tag from "primevue/tag";
import ScrollTop from "primevue/scrolltop";
import Divider from "primevue/divider";
import Galleria from "primevue/galleria";
import Timeline from "primevue/timeline";
// form components
import Dialog from "primevue/dialog";
import InputMask from "primevue/inputmask";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown"; //icons
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";

import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";

//themes
// import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
// import "primevue/resources/themes/bootstrap4-light-purple/theme.css"
// import "primevue/resources/themes/bootstrap4-dark-blue/theme.css"
// import "primevue/resources/themes/bootstrap4-dark-purple/theme.css"
// import "primevue/resources/themes/md-light-indigo/theme.css"
// import "primevue/resources/themes/md-light-deeppurple/theme.css"
// import "primevue/resources/themes/md-dark-indigo/theme.css"
// import "primevue/resources/themes/md-dark-deeppurple/theme.css"
// import "primevue/resources/themes/mdc-light-indigo/theme.css"
// import "primevue/resources/themes/mdc-light-deeppurple/theme.css"
// import "primevue/resources/themes/mdc-dark-indigo/theme.css"
// import "primevue/resources/themes/mdc-dark-deeppurple/theme.css"
// import "primevue/resources/themes/tailwind-light/theme.css"
// import "primevue/resources/themes/fluent-light/theme.css"
// import "primevue/resources/themes/lara-light-indigo/theme.css"
// import "primevue/resources/themes/lara-dark-indigo/theme.css"
// import "primevue/resources/themes/lara-light-purple/theme.css"
// import "primevue/resources/themes/lara-dark-purple/theme.css"
// import "primevue/resources/themes/lara-light-blue/theme.css"
// import "primevue/resources/themes/lara-dark-blue/theme.css"
// import "primevue/resources/themes/lara-light-teal/theme.css"
// import "primevue/resources/themes/lara-dark-teal/theme.css"
// import "primevue/resources/themes/saga-blue/theme.css"
// import "primevue/resources/themes/saga-green/theme.css"
import "primevue/resources/themes/saga-orange/theme.css";

// import "primevue/resources/themes/saga-purple/theme.css"
// import "primevue/resources/themes/vela-blue/theme.css"
// import "primevue/resources/themes/vela-green/theme.css"
// import "primevue/resources/themes/vela-orange/theme.css"
// import "primevue/resources/themes/vela-purple/theme.css"
// import "primevue/resources/themes/arya-blue/theme.css"
// import "primevue/resources/themes/arya-green/theme.css"
// import "primevue/resources/themes/arya-orange/theme.css"
// import "primevue/resources/themes/arya-purple/theme.css"
// import "primevue/resources/themes/nova/theme.css"
// import "primevue/resources/themes/nova-alt/theme.css"
// import "primevue/resources/themes/nova-accent/theme.css"
// import "primevue/resources/themes/nova-vue/theme.css"
// import "primevue/resources/themes/luna-amber/theme.css"
// import "primevue/resources/themes/luna-blue/theme.css"
// import "primevue/resources/themes/luna-green/theme.css"
// import "primevue/resources/themes/luna-pink/theme.css"
// import "primevue/resources/themes/rhea/theme.css"
import firebaseApp from "./services/firebase.service";
const fb = firebaseApp;
createApp(App)
  .use(router)
  .use(PrimeVue)
  .use(Plugin)
  .use(VueMapboxTs)
  .component("Image", Image)
  .component("TabView", TabView)
  .component("TabPanel", TabPanel)
  .component("ScrollPanel", ScrollPanel)
  .component("Panel", Panel)
  .component("Tag", Tag)
  .component("Galleria", Galleria)
  .component("ScrollTop", ScrollTop)
  .component("Dialog", Dialog)
  .component("Divider", Divider)
  .component("InputMask", InputMask)
  .component("InputNumber", InputNumber)
  .component("Password", Password)
  .component("Dropdown", Dropdown)
  .component("InputText", InputText)
  .component("Button", Button)
  .component("Checkbox", Checkbox)
  .component("Timeline", Timeline)
  .directive("tooltip", Tooltip)
  .mount("#app");
