
import { defineComponent } from "vue";

import FunFly from "@/components/FunFly.vue";
import DailyRace from "@/components/DailyRace.vue";
import BivyRace from "@/components/BivyRace.vue";
import Registration from "@/components/Registration.vue";
import SignIn from "@/components/SignIn.vue";
import Retrieves from "@/components/Retrieves.vue";

export default defineComponent({
  name: "Home",
  components: {
    FunFly,
    DailyRace,
    BivyRace,
    Retrieves,
    Registration,
    SignIn,
  },

  setup() {
    // const defaultVideoSrc =
    //   "https://firebasestorage.googleapis.com/v0/b/highlonesome-flyin.appspot.com/o/preview4.mp4?alt=media&token=0d7e8db3-cbac-4c8b-86b1-07a2047e01f4";
    // const videosSrc = [
    //   {
    //     src: "https://firebasestorage.googleapis.com/v0/b/highlonesome-flyin.appspot.com/o/preview4.mp4?alt=media&token=0d7e8db3-cbac-4c8b-86b1-07a2047e01f4",
    //     res: 450,
    //     autoplay: true,
    //   },
    // ];
    const images = [
      {
        itemImageSrc: "./assets/img/ranch/0.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/1.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/2.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/3.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/5.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/6.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/7.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/8.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/10.jpeg",
        alt: "Loading . . .",
      },
      {
        itemImageSrc: "./assets/img/ranch/11.jpeg",
        alt: "Loading . . .",
      },
    ];
    return {
      images,
      active: 0,
    };
  },
});
