// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBEgEw976NqZ9thH1BeOJ1l6SVt-QWx1vQ",
  authDomain: "highlonesome-flyin.firebaseapp.com",
  projectId: "highlonesome-flyin",
  storageBucket: "highlonesome-flyin.appspot.com",
  messagingSenderId: "765088727488",
  appId: "1:765088727488:web:be7888d7796ebbe5824825",
};
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase
export default firebaseApp;
