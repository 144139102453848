<template>
  <div class="login-form">
    <Dialog v-model:visible="showMessage" :breakpoints="{ '960px': '80vw' }" :style="{ width: '30vw' }" position="top">
      <div class="flex align-items-center flex-column pt-6 px-3">
        <div v-if="authErrorMesg">
          <i class="pi pi-exclamation-circle" :style="{ fontSize: '5rem', color: 'red' }"></i>
          <h5>{{ authErrorMesg }}</h5>
        </div>
      </div>
      <template #footer>
        <div class="flex justify-content-center">
          <Button label="OK" @click="toggleDialog" class="p-button-text" />
        </div>
      </template>
    </Dialog>

    <div class="flex justify-content-center">
      <div class="card">
        <h5 class="text-center">Log In</h5>
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
          <div class="field">
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-envelope" />
              <InputText id="signin-email" v-model="v$.email.$model"
                :class="{ 'p-invalid': v$.email.$invalid && submitted }" aria-describedby="email-error" />
              <label for="signin-email" :class="{ 'p-error': v$.email.$invalid && submitted }">Email*</label>
            </div>
            <span v-if="v$.email.$error && submitted">
              <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{ error.$message }}</small>
              </span>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending" class="p-error">{{
              v$.email.required.$message.replace("Value", "Email") }}</small>
          </div>
          <div class="field">
            <div class="p-float-label">
              <Password :feedback="false" id="signin-password" v-model="v$.password.$model"
                :class="{ 'p-invalid': v$.password.$invalid && submitted }" toggleMask>
              </Password>
              <label for="signin-password" :class="{ 'p-error': v$.password.$invalid && submitted }">Password*</label>
            </div>
            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending" class="p-error">{{
              v$.password.required.$message.replace("Value", "Password")
            }}</small>
          </div>
          <Button type="submit" label="Log In" class="mt-2" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { getAuth, signInWithEmailAndPassword, connectAuthEmulator } from "firebase/auth";

export default defineComponent({
  components: {},
  setup() {
    const loginFormData = reactive({
      email: "",
      password: "",
    });
    const rules = {
      email: { required, email },
      password: { required },
    };
    const router = useRouter();
    const submitted = ref(false);
    const showMessage = ref(false);
    const authErrorMesg = ref();
    const v$ = useVuelidate(rules, loginFormData);

    const handleSubmit = (isFormValid) => {
      // console.log("Login");
      submitted.value = true;
      if (!isFormValid) {
        // console.log("validation fail");
        return;
      }
      const auth = getAuth();
      // connectAuthEmulator(auth, "http://127.0.0.1:9099");

      signInWithEmailAndPassword(
        auth,
        loginFormData.email,
        loginFormData.password
      )
        .then((data) => {
          router.push("/profile");
        })
        .catch((err) => {
          switch (err.code) {
            case "auth/invalid-email":
              authErrorMesg.value = "Invalid Email";
              break;
            case "auth/user-not-found":
              authErrorMesg.value = "Account not found";
              break;
            case "auth/wrong-password":
              authErrorMesg.value = "Incorrect password";
              break;
            default:
              authErrorMesg.value = null;
              break;
          }
          toggleDialog();
        });
    };

    const toggleDialog = () => {
      showMessage.value = !showMessage.value;

      if (!showMessage.value) {
        resetForm();
      }
    };

    const resetForm = () => {
      loginFormData.email = "";
      loginFormData.password = "";
      submitted.value = false;
    };

    return {
      v$,
      handleSubmit,
      toggleDialog,
      submitted,
      showMessage,
      authErrorMesg,
    };
  },
});
</script>

<style land="less" scoped>
.card {
  min-width: 450px;
}

form {
  margin-top: 3rem;
}

.field {
  margin-bottom: 1.5rem;
}
</style>