
import { defineComponent } from "vue";
import Waypoints from "@/components/Waypoints.vue";

export default defineComponent({
  components: {
    Waypoints,
  },
  setup() {
    const mapboxAccessToken = process.env.VUE_APP_MAPBOX_API_KEY;
    const mapCenter = [-108.438723, 39.437221];

    return { mapboxAccessToken, mapCenter };
  },
});
